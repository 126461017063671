import React, { useEffect, useState } from "react";

import { getList, URLPRIFIX } from "../../functions/api";
import { getlocal } from "../../functions/localstorage";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { CgProfile } from "react-icons/cg";
import { RiAdminLine } from "react-icons/ri";
import { MdContentPasteSearch } from "react-icons/md";
import { MdOutlineDashboard } from "react-icons/md";
import { SiPhpmyadmin } from "react-icons/si";
import { useStore } from "../../Components/StateProvider";
import Sider from "../../Components/Layout/Sider";
import LoadingSpinner from "../../Components/Elements/LoadingSpinner";
import { GiHamburgerMenu } from "react-icons/gi";
import { contentHeader } from "../../Constancs/cssconst";

const AccountsLayout = ({ children, HeaderTitle }) => {
  const store = useStore();
  const user = getlocal("system")?.user || {};
  const [sider, setsider] = useState("max-sm:hidden");
  const props = getlocal("properties");
  // console.log("props", props);

  let category = {};

  let menus = {
    Dashboard: {
      "meta-data": { icon: <MdOutlineDashboard />, parent: true },
      link: "/" + URLPRIFIX + "/accounts/dashboard",
    },
    Master: {
      "meta-data": { icon: <SiPhpmyadmin />, parent: true },
      child: {
        Groups: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/accounts/group",
          disabled: !props?.users?.view,
        },
        Ledger: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/accounts/ledger",
          disabled: !props?.users?.view,
        },
      },
    },
    Transaction: {
      disabled: user?.role != "1",
      "meta-data": { icon: <RiAdminLine />, parent: true },
      child: {
        Sales: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/accounts/sales/create",
          // disabled: !props?.users?.view,
        },
        Invoice: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/accounts/invoice/create",
          // disabled: !props?.users?.view,
        },
        Receipt: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/accounts/receipt/create",
          // disabled: !props?.users?.view,
        },
        Purchase: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/accounts/purchase/create",
          // disabled: !props?.users?.view,
        },
        Payment: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/accounts/payment/create",
          // disabled: !props?.users?.view,
        },
        Journals: {
          "meta-data": { parent: false },
          link: "/" + URLPRIFIX + "/accounts/journal/create",
          // disabled: !props?.users?.view,
        },
      },
    },
    // Reports: {
    //   "meta-data": { icon: <MdContentPasteSearch />, parent: true },
    //   child: {},
    // },
  };

  let AccountsMenu = {
    Sales: {
      // disabled: user?.role != "1",
      "meta-data": {
        // icon: <RiAdminLine />,
        parent: true,
      },
      // child: {
      //   Users: {
      //     "meta-data": { parent: false },
      //     link: "/" + URLPRIFIX + "/users/list",
      //     disabled: !props?.users?.view,
      //   },
      // },
    },
  };

  useEffect(() => {
    store.setmodule("Accounts", {});
    store.stopLoading();
    getList({
      listUrl: "/clientcategory/getClientCategorylist",
      field: "",
      sort: "asc",
      page: 1,
      count: 100,
    }).then((res) => {
      console.log("Responces of category");
      if (res.success) {
        res?.data?.list?.map((item) => {
          category[item.categoryName] = {
            "meta-data": { icon: "", parent: false },
            link: "/" + URLPRIFIX + "/nonlitigation/" + item.categoryName,
          };
        });
      }
    });
    // Hotel: {
    //   "meta-data": { icon: "", parent: false },
    //   link: "/demo/nonlitigation/hotel",
    // },
  }, []);

  // the menu has some indicators to classify
  // every object has major components like
  //   1. "meta-data" - has the basic infor like icon location and this object is parent or not as Boolean
  //   2. child - which has the info like what are the child the going to have
  //   3. link - which create the object as a link button so we can easy to navigate to the link
  //   4. button - which help to perform any function which the store has

  return (
    <>
      <main className=" h-screen max-sm:h-svh bg-secondary text-primary font-Poppins grid grid-rows-12 relative">
        <div className=" row-span-2 max-sm:row-span-1">
          <Header Header={HeaderTitle} store={store} setsider={setsider} />
        </div>
        <div className="grid grid-cols-10 row-span-10 max-sm:row-span-11">
          <div
            className={
              "col-span-2 h-full bg-primary rounded-tr-[50px] Scrollleft " +
              sider
            }
          >
            <div className="innerscroll bg-primary text-secondary h-full max-sm:rounded-none max-sm:absolute max-sm:right-0 max-sm:w-full max-sm:z-50">
              <Sider menus={menus} setsider={setsider} />
            </div>
          </div>
          <div className="col-span-8 max-sm:col-span-10 overflow-x-auto px-[2%] relative">
            {store.getsystemvalue("loading") && <LoadingSpinner />}
            {children}{" "}
          </div>
        </div>
      </main>
    </>
  );
};

const Header = ({ Header = "", store, setsider }) => {
  const user = getlocal("system")?.user || {};

  const [isPopupVisible, setPopupVisible] = useState({
    notification: false,
    profile: false,
  });
  useEffect(() => {
    getNotification();
  }, []);

  function getNotification() {
    getList({
      listUrl: "/api/SentMessage/byuserid/" + user.id,
    }).then((res) => {
      console.log("notification", res);
      store.setsystemvalue("Notification", res?.data);
    });
  }
  const togglePopup = (field) => {
    let newfield = {};
    if (field === "notification") {
      newfield = {
        notification: !isPopupVisible.notification,
        profile: false,
      };
    } else {
      newfield = {
        notification: false,
        profile: !isPopupVisible.profile,
      };
    }
    setPopupVisible((prev) => {
      prev = newfield;
      return { ...prev };
    });
  };
  return (
    <div className="grid grid-cols-10 h-full ">
      <div className=" col-span-2 ">
        <div
          className="flex justify-center items-center h-full px-[6%] gap-2 cursor-pointer"
          onClick={(e) => {
            e.preventDefault();
            store.navTo("/" + URLPRIFIX + "/dashboard");
          }}
        >
          <img
            src="/Assets/images/Logo.png"
            alt="Logo"
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[42px] md:h-[30px] lg:w-[60px] lg:h-[40px] "
          />
          <h1
            className="font-bold max-sm:text-[28px] md:text-[12px] lg:text-[16px] xl:text-[20px] max-sm:hidden cursor-pointer z-50"
            onClick={() => {}}
          >
            Absolute Legal Law Firm
          </h1>
        </div>
      </div>
      <div className=" col-span-6">
        <div className="flex items-center h-full pl-[5%]  font-bold max-sm:text-[38px] md:text-[30px] lg:text-[38px]">
          {Header}
        </div>
      </div>
      <div className="col-span-2">
        <div className="flex items-center justify-center h-full md:gap-6 lg:gap-8 xl:gap-12 max-sm:hidden relative">
          <img
            src="/Assets/images/notification.png"
            alt="Notification"
            id="notification"
            onClick={(e) => {
              e.preventDefault();
              togglePopup("notification");
              getNotification();
              console.log("On click detected ");
            }}
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[25px] md:h-[25px] lg:w-[40px] lg:h-[40px] cursor-pointer z-50 hover:scale-110 hover:animate-pulse hover:duration-150 "
          />

          <div
            className={
              "absolute top-full right-6 mt-0 bg-secondary min-w-[320px] max-h-[350px] p-4 shadow-2xl rounded-2xl border border-[#a9a9ff] border-1 transition-all ease-in-out " +
              (isPopupVisible.notification
                ? " z-10 opacity-100 delay-75 translate-y-0 scale-100 duration-700"
                : " -Z-10 opacity-0 -translate-y-32 translate-x-4 scale-50")
            }
          >
            <h1 className={contentHeader}>Notifications</h1>

            <button
              className={
                "absolute right-5 top-4 text-[18px] bg-primary lg:h-6 lg:w-6 h-9 w-9 flex justify-center items-center rounded-full  text-secondary hover:bg-tertiary hover:bg-opacity-45" +
                (isPopupVisible.notification
                  ? " cursor-pointer "
                  : " cursor-default ")
              }
              onClick={() =>
                isPopupVisible.notification && togglePopup("notification")
              }
            >
              &times;
            </button>

            {store.getsystemvalue("Notification") &&
            store.getsystemvalue("Notification").length > 0 ? (
              <div className="overflow-y-auto max-h-[250px]">
                {store.getsystemvalue("Notification").map((item, index) => (
                  <div
                    key={index}
                    className="mb-2 p-1 pt-5 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative"
                  >
                    {/* <div className="absolute right-1  hover:bg-[#a9a9ff] hover:bg-opacity-45 ">
                      <IoChevronForwardOutline className="w-6 h-6" />
                    </div> */}
                    <h4 className="text-sm font-bold">{item.title}</h4>
                    <p className="text-sm my-3">{item.body}</p>
                    {/* <p className="text-sm">
                      {`${item.sentAt.split("T")[0]} at ${item.sentAt
                        .split("T")[1]
                        .substring(0, 5)}`}
                    </p> */}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-gray-600 text-sm">You have no notifications</p>
            )}
          </div>

          <img
            src="/Assets/images/profile.png"
            alt="Logo"
            onClick={() => togglePopup("profile")}
            className="max-sm:w-[92px] max-sm:h-[67px] md:w-[25px] md:h-[25px] lg:w-[40px] lg:h-[40px] cursor-pointer z-50 hover:scale-110"
          />
          <div
            className={
              "absolute top-full right-6 mt-0 bg-secondary min-w-[220px] max-h-[350px] p-4 shadow-2xl rounded-2xl border border-[#a9a9ff] border-1 transition-all ease-in-out " +
              (isPopupVisible.profile
                ? " z-10 opacity-100 delay-75 translate-y-0 scale-100 duration-700 translate-x-0"
                : " -Z-10 opacity-0 -translate-y-24 translate-x-10 scale-50")
            }
          >
            <div>
              <h1 className={contentHeader}>{user.name?.toUpperCase()}</h1>
              <h1 className={"text-[12px]"}>profile</h1>
            </div>
            <button
              className={
                "absolute right-5 top-4 text-[18px] bg-primary lg:h-6 lg:w-6 h-9 w-9 flex justify-center items-center rounded-full  text-secondary hover:bg-tertiary hover:bg-opacity-45" +
                (isPopupVisible.profile
                  ? " cursor-pointer "
                  : " cursor-default ")
              }
              onClick={() => isPopupVisible.profile && togglePopup("profile")}
            >
              &times;
            </button>
            <div className="overflow-y-auto max-h-[250px]">
              <div
                className={
                  "mb-2 p-1 pt-3 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative " +
                  (isPopupVisible.profile
                    ? " cursor-pointer "
                    : " cursor-default ")
                }
              >
                <h1
                  onClick={() => {
                    store.navTo("/" + URLPRIFIX + "/users/profile/" + user.id);
                    isPopupVisible.profile && togglePopup("profile");
                  }}
                >
                  My Profile
                </h1>
              </div>
              <div
                className={
                  "mb-2 p-1 pt-3 border-b border-gray-300 hover:bg-tertiary hover:bg-opacity-45 relative " +
                  (isPopupVisible.profile
                    ? " cursor-pointer "
                    : " cursor-default ")
                }
              >
                <h1
                  onClick={() => {
                    store.logout();
                  }}
                >
                  Logout
                </h1>
              </div>
            </div>
          </div>
        </div>

        <div className="items-center justify-center h-full md:gap-6 lg:gap-8 max-sm:flex hidden">
          <div
            className="text-[38px]"
            onClick={(e) => {
              e.preventDefault();
              setsider((prev) => {
                if (prev === "max-sm:hidden") {
                  prev = "max-sm:block";
                } else {
                  prev = "max-sm:hidden";
                }
                return prev;
              });
            }}
          >
            <GiHamburgerMenu />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountsLayout;
