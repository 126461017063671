"use client";
import { useParams } from "react-router-dom";
import Dropdown from "../../Components/Fields/Dropdown";
import FileInput from "../../Components/Fields/FileInput";
import Journal from "../../Components/Fields/Journal";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import {
  CheckMandatory,
  ErrorAlert,
  saveRecord,
  SuccessAlert,
} from "../../functions/api";
import { getlocal } from "../../functions/localstorage";
import React, { useEffect } from "react";
import { PrimaryBTN } from "../../Constancs/cssconst";
import Attachement from "../../Components/Fields/Attachement";
import { IoMdAdd } from "react-icons/io";
import LoadingSpinner from "../../Components/Elements/LoadingSpinner";

// const querystring = require("querystring");

const FilmFormCreate = () => {
  const params = useParams();

  const store = useStore();

  const init = {
    activeform: 0,
    film: params.filmname || "",
    category_of_Service: "",
    nature_of_Service: "",
    name_of_Artist: "",
    email: "",
    phone_no: "",
    father_Name: "",
    addres_of_Artist: "",
    category_Id: "",
    client_Id: "",
    paN_Number: "",
    aadhar_Number: "",
    PAN: "",
    Aadhar: "",
    gst: "",
    GST_Certificate: "",
    payment_Deal: "",
    remarks: "",
    total_Remuneration: "",
    no_of_Shooting_Days: "",
    no_of_Months: "",
    payment_Trance: [
      { name: "Advance", value: "20000" },
      { name: "50%", value: "50000" },
      { name: "10%", value: "100000" },
    ],
    assistants_Payments: "",
    account_no: "",
    account_holder_Name: "",
    bank_Name: "",
    branch: "",
    ifsC_Code: "",
    attachmenturl: [],
    paymentAttachmentURL: [],
    order: [
      { Process: "Personal Details", stage: false },
      { Process: "ID Proofs", stage: false },
      { Process: "Bank Details", stage: false },
      { Process: "Payments & Shooting Days", stage: false },
      // { Process: "Contact Details", stage: false },
    ],
  };

  useEffect(() => {
    store.setmodule("clientform" + params.categoryid, init);
    store.setvalue("film", params.filmname);
    store.setprops("film", { readonly: true });
    // props for the Mandatory field
    mandatoryfields(0);
  }, [params.categoryid]);

  function mandatoryfields(order) {
    if (order === 0) {
      store.setprops("name_of_Artist", { mandatory: true });
      store.setprops("email", { mandatory: true });
      store.setprops("account_no", { mandatory: false });
      store.setprops("account_holder_Name", { mandatory: false });
      store.setprops("ifsC_Code", { mandatory: false });
      store.setprops("no_of_Shooting_Days", { mandatory: false });
      store.setprops("payment_Trance", { mandatory: false });
    } else if (order === 2) {
      store.setprops("name_of_Artist", { mandatory: false });
      store.setprops("email", { mandatory: false });
      store.setprops("account_no", { mandatory: true });
      store.setprops("account_holder_Name", { mandatory: true });
      store.setprops("ifsC_Code", { mandatory: true });
      store.setprops("no_of_Shooting_Days", { mandatory: false });
      store.setprops("payment_Trance", { mandatory: false });
    } else if (order === 3) {
      store.setprops("name_of_Artist", { mandatory: false });
      store.setprops("email", { mandatory: false });
      store.setprops("account_no", { mandatory: false });
      store.setprops("account_holder_Name", { mandatory: false });
      store.setprops("ifsC_Code", { mandatory: false });
      store.setprops("no_of_Shooting_Days", { mandatory: true });
      store.setprops("payment_Trance", { mandatory: true });
    }
  }

  function OnSubmit() {
    store.startLoading();
    const ActualRecord = store.getrecord("clientform" + params.categoryid);
    console.log("Save records - ", ActualRecord);

    let data = { ...ActualRecord };
    data = {
      formDetails: {
        form_id: params.formid,
        category_id: Number(params.categoryid),
        contact_id: Number(params.clientId),
        status: "Submitted",
        film_id: Number(params.filmid),
      },
      industrialNames: {
        film: data.film,
        category_of_Service: data.category_of_Service,
        nature_of_Service: data.nature_of_Service,
        name_of_Artist: data.name_of_Artist,
        email: data.email,
        phone_no: data.phone_no,
        father_Name: data.father_Name,
        addres_of_Artist: data.addres_of_Artist,
      },
      idProofDocs: {
        paN_Number: data.paN_Number,
        paN_docs_url: data.paN_docs_url,
        aadhar_Number: data.aadhar_Number,
        aadhar_docs_url: data.aadhar_docs_url,
        gst: data.gst === "true" ? true : false,
        gsT_doc_url: data.gsT_doc_url,
        attachmenturl: data.attachmenturl,
      },
      payment: {
        payment_Deal: data.payment_Deal,
        total_Remuneration: data.total_Remuneration,
        no_of_Shooting_Days: data.no_of_Shooting_Days,
        no_of_Months: data.no_of_Months,
        payment_Trance: JSON.stringify(data.payment_Trance),
        assistants_Payments: data.assistants_Payments,
        remarks: data.remarks,
      },
      bankDetails: {
        account_no: data.account_no,
        account_holder_Name: data.account_holder_Name,
        bank_Name: data.bank_Name,
        branch: data.branch,
        ifsC_Code: data.ifsC_Code,
        attachmenturl: data.paymentAttachmentURL,
      },
    };
    // store.state.records["clientform" + params.categoryid];
    console.log("Save records - ", data);

    saveRecord("/forms/addformdetails", data).then((res) => {
      console.log("Save records after - ", res);
      if (res.success) {
        let orderdata = order;
        SuccessAlert("Form Submitted Successfully");
        orderdata[store.getvalue("activeform")].stage = true;
        store.setvalue("order", orderdata);
        store.setvalue("activeform", 4);
        store.stopLoading();
      } else {
        ErrorAlert("Form error. Please check all the section");
        store.stopLoading();
      }
    });
  }

  const order =
    (getlocal("records") &&
      getlocal("records")["clientform" + params.categoryid] &&
      getlocal("records")["clientform" + params.categoryid].order) ||
    [];
  return (
    <div className="h-screen ">
      <div className="grid grid-cols-4 h-full">
        <Sidebar Title={"Artist and Technician Form"} order={order} />
        <div className="bg-secondary text-primary col-span-3 relative">
          {/* Header For mention the Page in the top */}
          <div className="flex h-[15%] px-[8%]  items-end">
            <h1 className="text-[26px] font-bold">
              {order[store.getvalue("activeform")]?.Process}
            </h1>
          </div>
          {/* Content Screen for the Forms */}
          {store.getvalue("activeform") === 0 ? (
            <FilmDetails params={params} store={store} />
          ) : store.getvalue("activeform") === 1 ? (
            <PANDetails params={params} store={store} />
          ) : store.getvalue("activeform") === 2 ? (
            <AccountDetails params={params} store={store} />
          ) : store.getvalue("activeform") === 3 ? (
            <>
              {store.getsystemvalue("loading") && <LoadingSpinner />}
              <ShootingDetails params={params} store={store} />
            </>
          ) : store.getvalue("activeform") === 4 ? (
            <SuccessSubmittion
              MandatoryFunction={mandatoryfields}
              initialValue_for_Reset={init}
              store={store}
            />
          ) : (
            <div className=" h-[75%] px-[10%] "></div>
          )}
          {/* Action Buttons to intract with the Pages */}
          {store.getvalue("activeform") !== 4 && (
            <div className={"flex  px-[10%] h-[10%] justify-end gap-5"}>
              {store.getvalue("activeform") > 0 && (
                <button
                  className="bg-tertiary text-primary border-primary border py-2 px-6 rounded-xl h-fit font-bold text-[12px] "
                  onClick={() => {
                    if (store.getvalue("activeform") > 0) {
                      let orderdata = order;
                      orderdata[store.getvalue("activeform") - 1].stage = false;
                      store.setvalue("order", orderdata);
                      store.setvalue(
                        "activeform",
                        store.getvalue("activeform") - 1
                      );
                      mandatoryfields(store.getvalue("activeform") - 1);
                    }
                  }}
                >
                  Back
                </button>
              )}
              <button
                className={
                  "bg-primary text-secondary py-2 px-6 rounded-xl h-fit font-bold text-[12px] "
                }
                onClick={() => {
                  if (CheckMandatory("clientform" + params.categoryid, store)) {
                    if (order.length - 1 === store.getvalue("activeform")) {
                      OnSubmit();
                    } else {
                      let orderdata = order;
                      orderdata[store.getvalue("activeform")].stage = true;
                      store.setvalue("order", orderdata);
                      // change the page for next process
                      store.setvalue(
                        "activeform",
                        store.getvalue("activeform") + 1
                      );
                      // trigger the mandatoryfields for specific page
                      mandatoryfields(store.getvalue("activeform") + 1);
                    }
                  }
                }}
              >
                {order.length - 1 === store.getvalue("activeform")
                  ? "Submit"
                  : "Next"}
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function Sidebar({ Title, order }) {
  return (
    <div className="bg-primary text-secondary col-span-1">
      <div className="flex h-[15%] px-[10%]  items-end">
        <img src="/Assets/images/Logo.png" className="h-[60%]" />
      </div>
      <div className="text-[26px] font-bold p-[10%]">{Title}</div>
      <div className="px-[10%] flex flex-col gap-10">
        {order.map((item, index) => (
          <div className="flex items-center gap-5 h-fit relative" key={index}>
            <div className=" flex flex-col justify-center items-center">
              <h1
                className={
                  "text-primary rounded-full w-8 h-8 col-span-1 flex justify-center items-center text-[18px] font-bold " +
                  (item.stage ? "bg-Jasmine" : "bg-secondary")
                }
              >
                {item.stage ? (
                  <img
                    className="h-8 py-2"
                    src="/Assets/images/checkicon.png"
                  />
                ) : (
                  index + 1
                )}
              </h1>
              {order.length - 1 !== index && (
                <h1
                  className={
                    "absolute top-[100%] border-2 h-[200%] min-h-10 w-1 " +
                    (item.stage ? "border-Jasmine" : "border-secondary")
                  }
                ></h1>
              )}
            </div>
            <div className="col-span-3 text-[16px] font-bold ">
              {item.Process}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

function FilmDetails({ store, params }) {
  return (
    <div className=" h-[75%] px-[8%] ">
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px]">
        <h1>Film Details</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
      </div>
      <div className="grid grid-cols-2 mt-1 gap-1">
        <String name="film" label="Flim Name" doubleline={true} />
        <Dropdown
          name="nature_of_Service"
          label="Nature Of Service"
          doubleline={true}
          list={[
            { value: "Major Artist", label: "Major Artist" },
            { value: "Supporting Artist", label: "Supporting Artist" },
            { value: "Writer", label: "Writer" },
            { value: "Co-Writer", label: "Co-Writer" },
            { value: "Director", label: "Director" },
            { value: "Co-Director", label: "Co-Director" },
            { value: "Dialogue Writer", label: "Dialogue Writer" },
            {
              value: "Associate Director",
              label: "Associate Director",
            },
            {
              value: "Assistant Director",
              label: "Assistant Director",
            },
            {
              value: "Production Executive",
              label: "Production Executive",
            },
            {
              value: "Production Manager",
              label: "Production Manager",
            },
            { value: "Cinematographer", label: "Cinematographer" },
            { value: "Art Director", label: "Art Director" },
            { value: "Costume Designer", label: "Costume Designer" },
            { value: "Costumer", label: "Costumer" },
            {
              value: "Stunt Choreographer",
              label: "Stunt Choreographer",
            },
            { value: "Makeup Man", label: "Makeup Man" },
            {
              value: "Still Photographer",
              label: "Still Photographer",
            },
            {
              value: "Dance Choreographer",
              label: "Dance Choreographer",
            },
            { value: "Editor", label: "Editor" },
            { value: "Script Consultant", label: "Script Consultant" },
            { value: "Others", label: "Others" },
          ]}
        />
        <Dropdown
          name="category_of_Service"
          label="Category of Service"
          doubleline={true}
          list={[
            { value: "artist", label: "Artist" },
            { value: "technician", label: "Technician" },
          ]}
        />
      </div>
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px]">
        <h1>Artist Details</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
      </div>
      <div className="grid grid-cols-2 mt-1 gap-1">
        <String
          name="name_of_Artist"
          label="Name of the Artist (As per Govt. ID)"
          doubleline={true}
        />
        <String name="father_Name" label="Father's Name" doubleline={true} />
        <String name="email" label="E-Mail" doubleline={true} />
        <String
          name="phone_no"
          label="Artist Contact Number"
          doubleline={true}
        />
        <div className="col-span-2">
          <Journal
            name="addres_of_Artist"
            label="Address of the Artist"
            doubleline={true}
          />
        </div>
      </div>
    </div>
  );
}

function PANDetails({ store, params }) {
  return (
    <div className=" h-[75%] px-[8%] ">
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px]">
        <h1>PAN Details</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
      </div>
      <div className="grid grid-cols-2 mt-1 gap-1">
        <String name="paN_Number" label="PAN Number" doubleline={true} />
        <div className="">
          {"PAN Copy (optional)"}
          <div className="flex pt-2">
            <Attachement
              name="paN_docs_url"
              fileurl={store.getvalue("paN_docs_url")}
            />
            <FileInput
              name="paN_docs_url"
              doubleline={true}
              prefix={params.formid}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px]">
        <h1>Aadhar Details</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
      </div>
      <div className="grid grid-cols-2 mt-1 gap-1">
        <String name="aadhar_Number" label="Aadhar Number" doubleline={true} />
        <div>
          {"Aadhar Copy (optional)"}
          <div className="flex pt-2">
            <Attachement
              name="aadhar_docs_url"
              fileurl={store.getvalue("aadhar_docs_url")}
            />
            <FileInput
              name="aadhar_docs_url"
              doubleline={true}
              prefix={params.formid}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px]">
        <h1>GST Details</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[80%] h-[1px]"></div>
      </div>
      <div className="grid grid-cols-2 mt-1 gap-1">
        <Dropdown
          name="gst"
          label="GST Number"
          doubleline={true}
          list={[
            { value: "true", label: "Registered" },
            { value: "false", label: "Un-Registered" },
          ]}
        />
        <div>
          {"GST Certificate (optional)"}
          <div className="flex pt-2">
            <Attachement
              name="gsT_doc_url"
              fileurl={store.getvalue("gsT_doc_url")}
            />
            <FileInput
              name="gsT_doc_url"
              doubleline={true}
              prefix={params.formid}
            />
          </div>
        </div>
      </div>
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px] ">
        <h1>Other Attachments</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[70%] h-[1px]"></div>
      </div>
      <div className="col-span-2  max-md:col-span-1 px-2">
        <div className="grid grid-cols-3">
          {store.getvalue("attachmenturl")?.length > 0 ? (
            store.getvalue("attachmenturl").map((item, index) => {
              // console.log("test 223", item);
              return (
                <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                  <Attachement name={"attachmenturl"} fileurl={item} />
                  <button
                    className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                    onClick={() => {
                      let arr = [];
                      store
                        .getvalue("attachmenturl")
                        .map((attachitem, attachindex) => {
                          if (attachindex !== index) {
                            arr.push(attachitem);
                          }
                        });
                      store.setvalue("attachmenturl", arr);
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })
          ) : (
            <>No Data</>
          )}
          <FileInput
            prefix={params.formid}
            onchange={(file) => {
              let attachmenturl = store.getvalue("attachmenturl") || [];
              attachmenturl.push(file);
              store.setvalue("attachmenturl", attachmenturl);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function AccountDetails({ store, params }) {
  return (
    <div className=" h-[75%] px-[8%] ">
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px]">
        <h1>Account Details</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[70%] h-[1px]"></div>
      </div>
      <div className="grid grid-cols-2 mt-1 gap-1">
        <String name="account_no" label="Account Number" doubleline={true} />
        <String
          name="account_holder_Name"
          label="Account Holder Name"
          doubleline={true}
        />
        <String name="bank_Name" label="Bank Name" doubleline={true} />
        <String name="branch" label="Branch" doubleline={true} />
        <String name="ifsC_Code" label="IFSC Code" doubleline={true} />
      </div>
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px] ">
        <h1>Bank Proofs</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[70%] h-[1px]"></div>
      </div>
      <div className="col-span-2  max-md:col-span-1 px-2">
        <div className="grid grid-cols-3">
          {store.getvalue("paymentAttachmentURL")?.length > 0 ? (
            store.getvalue("paymentAttachmentURL").map((item, index) => {
              // console.log("test 223", item);
              return (
                <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                  <Attachement name={"paymentAttachmentURL"} fileurl={item} />
                  <button
                    className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                    onClick={() => {
                      let arr = [];
                      store
                        .getvalue("paymentAttachmentURL")
                        .map((attachitem, attachindex) => {
                          if (attachindex !== index) {
                            arr.push(attachitem);
                          }
                        });
                      store.setvalue("paymentAttachmentURL", arr);
                    }}
                  >
                    X
                  </button>
                </div>
              );
            })
          ) : (
            <>No Data</>
          )}
          <FileInput
            prefix={params.formid}
            onchange={(file) => {
              let attachmenturl = store.getvalue("paymentAttachmentURL") || [];
              attachmenturl.push(file);
              store.setvalue("paymentAttachmentURL", attachmenturl);
            }}
          />
        </div>
      </div>
    </div>
  );
}

function ShootingDetails({ store, params }) {
  return (
    <div className=" h-[75%] px-[8%] ">
      <div className="flex items-center gap-4 text-Bistre_Brown text-[20px] font-bold py-[8px]">
        <h1>Shooting Details</h1>
        <div className="border-b-Bistre_Brown  border-b-[.5px] w-[70%] h-[1px]"></div>
      </div>
      <div className="grid grid-cols-2 mt-1 gap-1">
        <Dropdown
          name="payment_Deal"
          label="Payment Deal"
          doubleline={true}
          list={[
            {
              value: "Fixed Remuneration",
              label: "Fixed Remuneration",
            },
            {
              value: "Per day Remuneration",
              label: "Per day Remuneration",
            },
            {
              value: "Monthly Remuneration",
              label: "Monthly Remuneration",
            },
          ]}
        />
        <String
          name="total_Remuneration"
          label="Total Remuneration"
          doubleline={true}
        />
        <String
          name="no_of_Shooting_Days"
          label="No. of Shooting Days"
          doubleline={true}
        />
        <String name="no_of_Months" label="No. of Months" doubleline={true} />
        <span className="px-3 text-[14px] ">
          <div className="flex flex-col gap-1">
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Payment Tranche
              {store.getprops("payment_Trance")?.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <div className="flex flex-col gap-2">
              {store.getvalue("payment_Trance").map((item, index) => (
                <div className="flex gap-2 items-center" key={index}>
                  <div className="border p-1 w-[70%] rounded-lg">
                    <input
                      type="text"
                      className=" w-full"
                      value={item.name}
                      onChange={(event) => {
                        let fieldName = event.target.value;
                        let payment_Trance = store.getvalue("payment_Trance");
                        payment_Trance[index].name = fieldName;
                        store.setvalue("payment_Trance", payment_Trance);
                      }}
                    />
                  </div>
                  =
                  <div className="border p-1 w-[30%] rounded-lg">
                    <input
                      type="text"
                      className=" w-full"
                      value={item.value}
                      onChange={(event) => {
                        let fieldValue = event.target.value;
                        let payment_Trance = store.getvalue("payment_Trance");
                        payment_Trance[index].value = fieldValue;
                        store.setvalue("payment_Trance", payment_Trance);
                      }}
                    />
                  </div>
                  <div
                    className="p-1 hover:border rounded-lg hover:bg-tertiary cursor-pointer"
                    onClick={() => {
                      let payment_Trance = store.getvalue("payment_Trance");
                      let new_payment_Trance = payment_Trance.filter(
                        (currentitem, currentindex) => currentindex != index
                      );
                      store.setvalue("payment_Trance", new_payment_Trance);
                    }}
                  >
                    X
                  </div>
                </div>
              ))}
              <div
                className="h-[32px] w-[32px] border rounded-lg font-bold flex justify-center items-center hover:bg-tertiary cursor-pointer"
                onClick={() => {
                  let payment_Trance = store.getvalue("payment_Trance");
                  payment_Trance.push({});
                  store.setvalue("payment_Trance", payment_Trance);
                }}
              >
                <IoMdAdd className=" text-lg" />
              </div>
            </div>
          </div>
        </span>

        <span className="flex flex-col gap-1">
          <String
            name="assistants_Payments"
            label="Assistants Payments"
            doubleline={true}
          />
          <Journal name="remarks" label="Remarks" doubleline={true} />
        </span>
      </div>
    </div>
  );
}

function SuccessSubmittion({
  initialValue_for_Reset,
  MandatoryFunction,
  store,
}) {
  return (
    <div className=" h-[75%] px-[10%] flex flex-col items-center">
      <div className="flex items-center justify-center gap-4 text-Bistre_Brown text-[28px] font-bold py-[1%]">
        <h1>Submitted Successfully</h1>
      </div>
      <div className="flex justify-center">
        <img className="h-[350px]" src="/Assets/images/success.png" />
      </div>
      <div
        className={PrimaryBTN + " cursor-pointer"}
        onClick={() => {
          store.setvalue("activeform", 0);
          store.reset(initialValue_for_Reset);
          MandatoryFunction(0);
          SuccessAlert("New Form Initated");
        }}
      >
        New Form
      </div>
    </div>
  );
}

export default FilmFormCreate;
