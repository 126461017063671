import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../../Components/StateProvider";
import { useEffect, useState } from "react";
import { getlocal } from "../../../functions/localstorage";
import {
  baseurl,
  ErrorAlert,
  GetRecords,
  UploadFile,
} from "../../../functions/api";
import AccountsLayout from "../AccountsLayout";
import FormView from "../../../Components/Elements/FormView";
import { MdOutlineFileUpload } from "react-icons/md";
import { FaRegEye } from "react-icons/fa6";
import { HiOutlineArrowSmDown, HiOutlineArrowSmUp } from "react-icons/hi";

const GroupForm = () => {
  const { option, id } = useParams();
  const navigate = useNavigate();
  const getrecord = `/users/users/${id}`;
  const attachment_Prefix = "Journal_";

  const store = useStore();

  const init = {
    groupName: "",
    linking: "",
    type: "",
    status: "true",
  };
  const initProps = {
    add: true,
    delete: false,
    on_off: false,
    print: false,
    update: false,
    view: false,
    groupName: {},
    status: {},
    type: {
      type_list: [{ name: "Credit" }, { name: "Debit" }],
    },
    linking: {
      linking_list: [
        { name: "Sundry Debtors" },
        { name: "Indirect Expenses" },
        { name: "ADVANCE TO VENDORS" },
        { name: "Fixed Assets" },
        { name: "BANK ACCOUNTS." },
        { name: "Capital Account" },
        { name: "Loans (Liability)" },
        { name: "Bank Charges" },
        { name: "Salary Account" },
        { name: "Cash-in-hand" },
        { name: "Sales Accounts" },
        { name: "Discount" },
        { name: "Current Liabilities" },
        { name: "Deposits (Asset)" },
        { name: "Purchase Accounts" },
        { name: "Salary Account" },
        { name: "Interest Recd. on FD" },
        { name: "Indirect Incomes" },
        { name: "Interest Paid" },
        { name: "Rent" },
        { name: "Cash-in-hand" },
        { name: "Current Liabilities" },
        { name: "Salary Account" },
        { name: "Stock-in-hand" },
        { name: "Vehicle Maintenance" },
      ],
    },
  };

  const [Record, setRecord] = useState(init);
  const [props, setprops] = useState(initProps);

  useEffect(() => {
    // const localprops = getlocal("properties")?.companies || {};

    // HandleMultiPropsChange(localprops);

    // console.log("Params",param);
    if (id !== undefined) {
      // store.setheader(option.toUpperCase() + " Company");
      // HandlePropsChange("company_status", { hidden: false });
      // if (option === "view") {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: true },
      //     company_name: { readonly: true },
      //     company_address_line1: { readonly: true },
      //     company_address_line2: { readonly: true },
      //     company_address_city: { readonly: true },
      //     company_address_pincode: { readonly: true },
      //     company_address_state: { readonly: true },
      //     company_phone_alternative: { readonly: true },
      //     company_website_url: { readonly: true },
      //     company_phone: { readonly: true },
      //     company_email: { readonly: true },
      //     company_status: { readonly: true },
      //   });
      // } else {
      //   HandleMultiPropsChange({
      //     company_logo: { readonly: false },
      //     company_name: { readonly: false },
      //     company_address_line1: { readonly: false },
      //     company_address_line2: { readonly: false },
      //     company_address_city: { readonly: false },
      //     company_address_pincode: { readonly: false },
      //     company_address_state: { readonly: false },
      //     company_phone_alternative: { readonly: false },
      //     company_website_url: { readonly: false },
      //     company_phone: { readonly: false },
      //     company_email: { readonly: false },
      //     company_status: { readonly: false },
      //   });
      // }
      // GetRecords(getrecord).then((res) => {
      //   console.log("Companies Data - ", res, res.record);
      //   if (res.success) {
      //     handleMultiFieldChange(res.record);
      //   } else if (res.code === 401) {
      //     store.logout();
      //     store.navTo("/login");
      //   }
      // });
    } else {
      store.setheader("CREATE NEW Company");
      // props for the Mandatory field
      HandleMultiPropsChange({
        groupName: { mandatory: true },
        type: { mandatory: true },
      });
    }
  }, [option, id]);

  useEffect(() => {
    console.log("Record State change -> ", Record);
  }, [Record]);

  // useEffect(() => {
  //   console.log("Props State change -> ", props);
  // }, [props]);

  function update() {}

  function Submit() {
    store.startLoading();
    let savedata = { ...Record };
    savedata.modifiedBy = store.getuser().id;
    savedata.createdBy = store.getuser().id;
    console.log("Submiting savedata", savedata);
    if (CheckMandatory()) {
      store.stopLoading();

      //   saveRecord(setrecord, savedata).then((res) => {
      //     console.log("Resonce of saveRecord - ", res);
      //     if (res.success) {
      //       store.stopLoading();
      //       SuccessAlert("User Submitted Successfully");
      //       store.navback();
      //     } else {
      //       store.stopLoading();
      //       ErrorAlert(
      //         res?.errormessage?.response?.data?.errorMessage ||
      //           " Error Found Please contact the Admin"
      //       );
      //     }
      //   });
    }
  }

  function CheckMandatory() {
    let out = true;
    let Fields = "";
    for (let obj in props) {
      if (typeof props[obj] === "object") {
        if (props[obj].mandatory) {
          if (Record[obj] == "" || Record[obj] == " ") {
            HandlePropsChange(obj, { mandatory: true, show: true });
            out = false;
          } else {
            HandlePropsChange(obj, { mandatory: true, show: false });
          }
        }
      }
    }
    out === false && ErrorAlert("Please fill the Mandatory Fields.");
    out === false && store.stopLoading();
    return out;
  }
  function handleFieldChange(fieldName, value) {
    setRecord((prev) => {
      return { ...prev, [fieldName]: value };
    });
  }
  function handleMultiFieldChange(value = {}) {
    setRecord((prev) => {
      for (let fieldName in value) {
        prev[fieldName] = value[fieldName];
      }
      return { ...prev };
    });
  }
  function HandlePropsChange(fieldName, value = {}) {
    setprops((prev) => {
      let oldprop = prev[fieldName];

      for (let prophead in value) {
        oldprop[prophead] = value[prophead];
      }
      prev[fieldName] = oldprop;
      return { ...prev };
    });
  }
  function HandleMultiPropsChange(NewProps = {}) {
    setprops((prev) => {
      for (let fieldName in NewProps) {
        let oldprop = prev[fieldName];
        let newprop = NewProps[fieldName];
        console.log("typeof newprop", typeof newprop);

        if (typeof newprop === "object") {
          for (let prophead in newprop) {
            oldprop[prophead] = newprop[prophead];
          }
          prev[fieldName] = oldprop;
        } else {
          prev[fieldName] = newprop;
        }
      }
      console.log("updated props", prev);

      return { ...prev };
    });
  }

  return (
    <AccountsLayout HeaderTitle={"Group Form"}>
      <FormView
        actions={[
          {
            Name: "Back",
            onclick: () => {
              navigate(-1);
            },
            visible: true,
          },
          {
            Name: "Submit",
            onclick: Submit,
            type: "primary",
            visible: id === undefined && props.add,
          },
        ]}
      >
        <div className="grid grid-cols-1 max-sm:grid-cols-1 gap-3 w-[80%]">
          <div // groupName
            className={
              "text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.groupName.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Group Name
              {props.groupName.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <input
              className={
                "w-full border rounded-lg font-medium disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] px-2 py-1 " +
                (props.groupName.mandatory && props.groupName.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              id={"groupName"}
              type="text"
              value={Record.groupName}
              disabled={props.groupName.readonly}
              onChange={(event) => {
                handleFieldChange("groupName", event.target.value);
              }}
            />
          </div>
          <div // linking
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.linking.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Parent Group
              {props.linking.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"linking"}
              className={
                " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                (props.linking.mandatory && props.linking.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("linking", event.target.value);
              }}
              value={Record.linking}
              disabled={props.linking.readonly}
            >
              <option value={""}>Please Choose</option>
              {props.linking.linking_list.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
          <div // type
            className={
              "flex text-[14px] w-full h-full px-3 flex-row items-center gap-3 " +
              (props.type.hidden ? " hidden " : " flex ")
            }
          >
            <label className={"w-fit  min-w-[150px] max-w-full"}>
              Type
              {props.type.mandatory && (
                <span className="text-[#ff2828] text-[14px] h-fit">*</span>
              )}
            </label>
            <select
              id={"type"}
              className={
                " w-full border rounded-lg px-2 py-1 disabled:bg-[#c6c6ca] disabled:text-[#4d4d4e] " +
                (props.type.mandatory && props.type.show
                  ? " rounded-2xl border-2 border-[#ff2828] "
                  : "")
              }
              onChange={(event) => {
                handleFieldChange("type", event.target.value);
              }}
              value={Record.type}
              disabled={props.type.readonly}
            >
              <option value={""}>Please Choose</option>
              {props.type.type_list.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      </FormView>
    </AccountsLayout>
  );
};

export default GroupForm;
