import Dropdown from "../../Components/Fields/Dropdown";
import {
  CheckMandatory,
  ErrorAlert,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  URLPRIFIX,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";
import { urPK } from "@mui/x-date-pickers/locales";
import { getlocal } from "../../functions/localstorage";

const ContactForm = () => {
  const { option, id } = useParams();
  const tablename = "contact";
  const setrecord = "/contact/addcontacts";
  const getrecord = `/contact/contacts/${id}`;
  const updaterecord = "/contact/updatecontacts/" + id;

  const store = useStore();
  const init = {
    clientName: "",
    phoneno: "",
    email: "",
    category_ID: "",
    url: "",
    activeStatus: true,
    film_Id: "",
    address: "",
    child: [],
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);

      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.clientName = res.record.parent[0].clientName;
          init.phoneno = res.record.parent[0].phoneno;
          init.email = res.record.parent[0].email;
          init.category_ID = res.record.parent[0].category_ID;
          init.activeStatus = res.record.parent[0].activeStatus;
          init.address = res.record.parent[0].address;
          init.film_Id = res.record.parent[0].film_Id;
          init.url = res.record.parent[0].url;
          // init.URL =
          //   window.location.origin +"/"+URLPRIFIX+
          //   "/forms/" +
          //   res.record.parent[0].id +
          //   "/" +
          //   res.record.parent[0].categoryid +
          //   "/2367481jjdc984j89duj29j";
          if (option === "view") {
            init.child = [
              {
                list: res.record.child,
                header: [
                  ["Name of Artist", "name_of_Artist"],
                  ["Father Name", "father_Name"],
                  ["E-Mail", "email"],
                  ["Action", "ACTION"],
                ],
                table: "form",
              },
            ];
            store.setprops("activeStatus", { hidden: false, readonly: true });
            store.setprops("url", { hidden: false, readonly: true });
            // props for the Mandatory field
            store.setprops("clientName", { mandatory: false, readonly: true });
            store.setprops("email", { mandatory: false, readonly: true });
            store.setprops("category_ID", { mandatory: false, readonly: true });
            store.setprops("film_Id", { mandatory: false, readonly: true });
            store.setprops("address", { mandatory: false, readonly: true });
            store.setprops("phoneno", { mandatory: false, readonly: true });
          } else {
            store.setprops("activeStatus", { hidden: false, readonly: false });
            store.setprops("url", { hidden: false, readonly: true });
            // props for the Mandatory field
            store.setprops("clientName", { mandatory: false, readonly: false });
            store.setprops("email", { mandatory: false, readonly: false });
            store.setprops("category_ID", {
              mandatory: false,
              readonly: false,
            });
            store.setprops("film_Id", { mandatory: false, readonly: false });
            store.setprops("address", { mandatory: false, readonly: false });
            store.setprops("phoneno", { mandatory: false, readonly: false });
          }
          store.reset(init);
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
      store.setprops("url", { hidden: true });
      // props for the Mandatory field
      store.setprops("clientName", { mandatory: true });
      store.setprops("email", { mandatory: true });
      store.setprops("category_ID", { mandatory: true });
      store.setprops("film_Id", { mandatory: true });
      store.setprops("address", { mandatory: false });
      store.setprops("phoneno", { mandatory: false });
    }
    GetListDropdown({
      listUrl: "/clientcategory/getClientCategorylist",
      field: "categoryName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/api/FilmMaster/getfilms",
      field: "film_name",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, []);

  function update() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    data.url = window.location.origin + "/" + URLPRIFIX + "/form";

    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        store.stopLoading();
        SuccessAlert("Contact Updated Successfully");

        store.navTo("/" + URLPRIFIX + "/contacts/list");
        store.reset(init);
      } else {
        console.log("errormessage", res);
        store.stopLoading();
        ErrorAlert(
          res?.errormessage?.data || " Error Found Please contact the Admin"
        );
      }
    });
  }
  function Submit() {
    store.startLoading();
    let data = store.getrecord();
    const filteredData = {
      clientName: data.clientName,
      activeStatus: data.activeStatus,
      address: data.address,
      category_ID: data.category_ID,
      email: data.email,
      film_Id: data.film_Id,
      phoneno: data.phoneno,
    };
    filteredData.createdBy = store.getuser().id;
    filteredData.url = window.location.origin + "/" + URLPRIFIX + "/forms";
    console.log("Submit", data, filteredData);
    if (CheckMandatory(tablename, store)) {
      saveRecord(setrecord, filteredData).then((res) => {
        console.log("Resonce of saveRecord - ", res);

        if (res.success) {
          res = res?.Id?.list;
          store.stopLoading();
          SuccessAlert("Contact Submitted Successfully");
          if (res != undefined) {
            store.navTo("/" + URLPRIFIX + "/contacts/edit/" + res.id);
            store.setprops("activeStatus", { hidden: false });
            store.setprops("url", { hidden: false });
            store.setvalue("url", res?.url);
            store.setvalue("activeStatus", res?.activeStatus);
          }
        } else {
          console.log("errormessage", res);
          store.stopLoading();
          ErrorAlert(
            res?.errormessage?.response?.data ||
              " Error Found Please contact the Admin"
          );
        }
      });
    }
  }
  function sentMail() {
    window.open(`mailto:${store.getvalue(
      "email"
    )}?subject=Request for Form Submission to Continue Your Process&body=Hi ${store.getvalue(
      "clientName"
    )}	
    %0a
    %0aTo proceed with the next steps, please complete the form at the link provided below:
    %0a
    %0aLink : ${store.getvalue("url")}
    %0a
    %0aPlease Note: This is an automated email. Kindly do not reply to this message.	
    %0a
    %0aRegards	
    %0aAbsolute Legal Law Firm`);
  }

  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            // store.navTo("/" + URLPRIFIX + "/contacts/list");
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Copy URL",
          onclick: () => {
            navigator.clipboard.writeText(store.getvalue("url"));
            SuccessAlert("Url Copied to clipboard");
          },
          visible: option !== undefined,
        },

        {
          Name: "Send to mail",
          onclick: sentMail,
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Re- Send to mail",
          onclick: sentMail,
          type: "primary",
          visible: option === "view",
        },
        {
          Name: "Create Project",
          type: "primary",
          onclick: () => {
            let data = store.getrecord();
            console.log("On Create Project - ", data);

            // store.setvaluetomodule("project", "projectName", data.film);
            store.setrecord("project", {
              contact_id: id,
              film_id: data.film_Id,
            });
            store.navTo(
              "/demo/projects/create?contact_id=readonly&film_id=readonly"
            );
          },
          visible: getlocal("properties")?.projects?.add && option === "view",
        },
      ]}
    >
      <div className="grid grid-cols-2 gap-6">
        <String name="clientName" label="Client Name :" />
        <String name="phoneno" label="Phone Number :" />
        <String name="email" label="E-mail :" />

        <Dropdown
          name="category_ID"
          label="Category :"
          list={store.getprops("categoryName_list") || []}
          onAdd={() => {
            store.navTo("/" + URLPRIFIX + "/categories/create");
          }}
        />
        <Dropdown
          name="film_Id"
          label="Film Name :"
          list={store.getprops("film_name_list") || []}
          onAdd={() => {
            store.navTo("/" + URLPRIFIX + "/filmmaster/create");
          }}
        />
        <String name="address" label="Address :" />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <String name="url" label="URL :" />
        <div className="col-span-2 px-2">
          {store.getvalue("child") &&
            store.getvalue("child").map((child) => (
              <>
                {" "}
                <div className="font-bold text-[20px]">
                  {child.table.toUpperCase()}
                </div>
                <ListView
                  Tablehead={
                    "min-w-[150px] pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[16px] xl:text-[20px] font-bold"
                  }
                  Tablerecord=" pr-[10px] max-sm:text-[26px] md:text-[20px] lg:text-[12px] xl:text-[16px] cursor-pointer "
                  onview={(index) => {
                    store.navTo("/demo/forms/view/" + index);
                  }}
                  height={"fit"}
                  header={child.header}
                  records={child.list}
                />
              </>
            ))}
        </div>
      </div>
    </FormView>
  );
};

export default ContactForm;
