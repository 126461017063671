import Dropdown from "../../Components/Fields/Dropdown";
import {
  CheckMandatory,
  getList,
  GetListDropdown,
  GetRecords,
  saveRecord,
  SuccessAlert,
  UpdateRecord,
  URLPRIFIX,
  WarningAlert,
} from "../../functions/api";
import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import String from "../../Components/Fields/String";
import { useStore } from "../../Components/StateProvider";
import FormView from "../../Components/Elements/FormView";
import ListView from "../../Components/Elements/ListView";
import DatePicker from "../../Components/Fields/DatePicker";
import Journal from "../../Components/Fields/Journal";
import FileInput from "../../Components/Fields/FileInput";
import Attachement from "../../Components/Fields/Attachement";
import { getlocal } from "../../functions/localstorage";

const TasksForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const { option, id } = useParams();
  const user = getlocal("system")?.user || {};

  const tablename = "task";
  const setrecord = "/subtask/addsubtasks";
  const getrecord = `/subtask/usersubtasks/${id}`;
  const updaterecord = "/subtask/updatesubtask/" + id;

  const store = useStore();
  const init = {
    project_id: "",
    form_id: "",
    film_id: "",
    contact_id: "",
    task_id: "",
    subtask_title: "",
    assigned_to: "",
    dueDate: "",
    priority: "",
    status: "",
    description: "",
    hold_note: "",
    rejected_reason: "",
    complete_note: "",
    activeStatus: true,
    attachmenturl: [],
    child: [],
  };

  useEffect(() => {
    // console.log("Params",param);

    store.setmodule(tablename, init);
    if (id != undefined) {
      store.setheader(option.toUpperCase() + " " + tablename);
      store.setprops("activeStatus", { hidden: false });
      store.setprops("Holdpopup", { hidden: true });
      // store.setprops("holdReason", { hidden: true });
      // store.setprops("complitionNotes", { hidden: true });

      if (option === "view") {
        store.setprops("project_id", { hidden: false, readonly: true });
        store.setprops("form_id", { hidden: false, readonly: true });
        store.setprops("film_id", { hidden: false, readonly: true });
        store.setprops("contact_id", { hidden: false, readonly: true });
        store.setprops("task_id", { hidden: false, readonly: true });
        store.setprops("subtask_title", { hidden: false, readonly: true });
        store.setprops("assigned_to", { hidden: false, readonly: true });
        store.setprops("dueDate", { hidden: false, readonly: true });
        store.setprops("priority", { hidden: false, readonly: true });
        store.setprops("status", { hidden: false, readonly: true });
        store.setprops("description", { hidden: false, readonly: true });
        store.setprops("activeStatus", { hidden: false, readonly: true });
      } else {
        store.setprops("project_id", { hidden: false, readonly: true });
        store.setprops("form_id", { hidden: false, readonly: true });
        store.setprops("film_id", { hidden: false, readonly: true });
        store.setprops("contact_id", { hidden: false, readonly: true });
        store.setprops("task_id", { hidden: false, readonly: true });
        store.setprops("subtask_title", { hidden: false, readonly: false });
        store.setprops("assigned_to", { hidden: false, readonly: false });
        store.setprops("dueDate", { hidden: false, readonly: false });
        store.setprops("priority", { hidden: false, readonly: false });
        store.setprops("status", { hidden: false, readonly: false });
        store.setprops("description", { hidden: false, readonly: false });
        store.setprops("activeStatus", { hidden: false, readonly: false });
      }

      GetRecords(getrecord).then(async (res) => {
        console.log(tablename + " Data - ", res, res.record);
        if (res.success) {
          init.project_id = res.record.parent[0].project_id;
          init.form_id = res.record.parent[0].form_id;
          init.film_id = res.record.parent[0].film_id;
          init.task_id = res.record.parent[0].task_id;
          init.contact_id = res.record.parent[0].contact_id;
          init.subtask_title = res.record.parent[0].subtask_title;
          init.assigned_to = res.record.parent[0].assigned_to;
          init.dueDate = res.record.parent[0].dueDate;
          init.priority = res.record.parent[0].priority;
          init.hold_note = res.record.parent[0].hold_note || "";
          init.rejected_reason = res.record.parent[0].rejected_reason || "";
          init.complete_note = res.record.parent[0].complete_note || "";
          init.status = res.record.parent[0].status;
          init.description = res.record.parent[0].description;
          init.editor_id = res.record.child[0].editor_id;
          init.activeStatus = res.record.parent[0].activeStatus;
          init.attachmenturl = res.record.parent[0].attachmenturl || [];

          store.reset(init);
          init.hold_note === "" &&
            store.setprops("hold_note", { hidden: true, readonly: true });
          init.complete_note === "" &&
            store.setprops("complete_note", { hidden: true, readonly: true });
          init.rejected_reason === "" &&
            store.setprops("rejected_reason", { hidden: true, readonly: true });
        }
      });
    } else {
      store.setheader("CREATE NEW " + tablename);
      store.setprops("activeStatus", { hidden: true });
      store.setprops("hold_note", { hidden: true, readonly: true });
      store.setprops("Holdpopup", { hidden: true });
      store.setprops("complete_note", { hidden: true, readonly: true });
      store.setprops("complitionNotes", { hidden: true });
      store.setprops("status", { hidden: true, readonly: true });
      store.setvalue("status", "Draft");
      // props for the Mandatory field
      store.setprops("subtask_title", { mandatory: true });
      store.setprops("dueDate", { mandatory: true });
      store.setprops("assigned_to", { mandatory: true });

      store.setprops(
        "project_id",
        queryParams.get("project_id")
          ? {
              mandatory: queryParams.get("project_id").includes("mandatory"),
              readonly: queryParams.get("project_id").includes("readonly"),
              hidden: queryParams.get("project_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "form_id",
        queryParams.get("form_id")
          ? {
              mandatory: queryParams.get("form_id").includes("mandatory"),
              readonly: queryParams.get("form_id").includes("readonly"),
              hidden: queryParams.get("form_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "film_id",
        queryParams.get("film_id")
          ? {
              mandatory: queryParams.get("film_id").includes("mandatory"),
              readonly: queryParams.get("film_id").includes("readonly"),
              hidden: queryParams.get("film_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "task_id",
        queryParams.get("task_id")
          ? {
              mandatory: queryParams.get("task_id").includes("mandatory"),
              readonly: queryParams.get("task_id").includes("readonly"),
              hidden: queryParams.get("task_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "contact_id",
        queryParams.get("contact_id")
          ? {
              mandatory: queryParams.get("contact_id").includes("mandatory"),
              readonly: queryParams.get("contact_id").includes("readonly"),
              hidden: queryParams.get("contact_id").includes("hidden"),
            }
          : { mandatory: true }
      );
      store.setprops(
        "priority",
        queryParams.get("priority")
          ? {
              mandatory: queryParams.get("priority").includes("mandatory"),
              readonly: queryParams.get("priority").includes("readonly"),
              hidden: queryParams.get("priority").includes("hidden"),
            }
          : { mandatory: false }
      );
      store.setprops(
        "description",
        queryParams.get("description")
          ? {
              mandatory: queryParams.get("description").includes("mandatory"),
              readonly: queryParams.get("description").includes("readonly"),
              hidden: queryParams.get("description").includes("hidden"),
            }
          : { mandatory: false }
      );
    }
    GetListDropdown({
      listUrl: "/project/getprojectlist",
      field: "projectName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/users/getusers",
      field: "userName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/tasks/getprojectstasklist",
      field: "task_title",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/forms/getformslist",
      field: "name_of_Artist",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/contact/getcontacts",
      field: "clientName",
      sort: "asc",
      count: 100,
      store: store,
    });
    GetListDropdown({
      listUrl: "/api/FilmMaster/getfilms",
      field: "film_name",
      sort: "asc",
      count: 100,
      store: store,
    });
  }, [option, id]);

  function update() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    GetRecords("/tasks/gettask/" + data.task_id).then((res) => {
      console.log("gettask ", res);

      if (res.success) {
        let parentDate = new Date(res.record.parent[0].dueDate || "");
        let childDate = new Date(data.dueDate || "");
        if (parentDate >= childDate) {
          UpdateRecord(updaterecord, data).then((res) => {
            if (res.success) {
              store.stopLoading();
              SuccessAlert("Task Updated Successfully");
              store.navback();
              store.reset(init);
            }
          });
        } else {
          store.stopLoading();
          WarningAlert(
            "Document Date Exist Project Date\nProject end Date - " +
              res.record.parent[0].dueDate.split("T")[0] +
              "\nDocument DueDate - " +
              data.dueDate.split("T")[0]
          );
        }
      } else {
        store.stopLoading();
        WarningAlert("UnKnown Error Found. Please contact Admin");
      }
    });
  }
  function inprogress() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.status = "inprogress";
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    UpdateRecord(updaterecord, data).then((res) => {
      if (res.success) {
        SuccessAlert("States Update to - " + res.Id.response.status);
        console.log("States Update to - ", res);
        store.setvalue("status", "inprogress");
        store.setvalue("hold_note", "");
        store.setprops("hold_note", { hidden: true, readonly: true });
        // store.navback();
        // store.reset(init);
      }
    });
  }
  function hold() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckMandatory(tablename, store)) {
      data.status = "hold";
      UpdateRecord(updaterecord, data).then((res) => {
        if (res.success) {
          store.setvalue("status", "hold");
          SuccessAlert("States Update to - " + res.Id.response.status);
          store.setprops("hold_note", { hidden: false, readonly: true });
          store.setprops("Holdpopup", { hidden: true });
        }
      });
    }
  }
  function complete() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckMandatory(tablename, store)) {
      data.status = "waitingforapproval";
      UpdateRecord(updaterecord, data).then((res) => {
        if (res.success) {
          store.setvalue("status", "waitingforapproval");
          SuccessAlert("States Update to - " + res.Id.response.status);
          store.setprops("complete_note", {
            hidden: false,
            readonly: true,
          });
          store.setprops("completePopup", { hidden: true });
        }
      });
    }
  }
  function rejected() {
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.id = Number(id);
    if (typeof data.activeStatus === "string") {
      data.activeStatus = data.activeStatus == "true" ? true : false;
    }
    if (CheckMandatory(tablename, store)) {
      data.status = "rejected";
      UpdateRecord(updaterecord, data).then((res) => {
        if (res.success) {
          store.setvalue("status", "rejected");
          SuccessAlert("States Update to - " + res.Id.response.status);
          store.setprops("rejected_reason", { hidden: false, readonly: true });
          store.setprops("rejectpopup", { hidden: true });
        }
      });
    }
  }
  function approved() {
    let data = store.getrecord();
    console.log("Data", data);

    UpdateRecord("/subtask/updatestatus/" + data.task_id + "/" + id).then(
      (res) => {
        console.log("updaterecord", res);

        if (res.success) {
          store.setvalue("status", "completed");
          SuccessAlert("States Update to - " + res.Id.status);
        }
      }
    );
  }

  function Submit() {
    store.startLoading();
    let data = store.getrecord();
    data.modifiedBy = store.getuser().id;
    data.createdBy = store.getuser().id;
    if (CheckMandatory(tablename, store)) {
      GetRecords("/tasks/gettask/" + data.task_id).then((res) => {
        if (res.success) {
          let parentDate = new Date(res.record.parent[0].dueDate || "");
          let childDate = new Date(data.dueDate || "");
          if (parentDate >= childDate) {
            saveRecord(setrecord, data).then((res) => {
              console.log("Responce of saveRecord - ", res);
              if (res.success) {
                store.stopLoading();
                SuccessAlert("Task Submitted Successfully");
                store.navTo("/" + URLPRIFIX + "/tasks/view/" + res.Id.id);
                store.reset(init);
              }
            });
          } else {
            store.stopLoading();
            WarningAlert(
              "Document Date Exist Project Date\nProject end Date - " +
                res.record.parent[0].dueDate.split("T")[0] +
                "\nDocument DueDate - " +
                data.dueDate.split("T")[0]
            );
          }
        } else {
        }
      });
    }
  }

  function FilmNameChange(film_id) {
    console.log("FilmNameChange");

    GetRecords("/api/FilmMaster/films/" + film_id).then((res) => {
      if (res.success) {
        let film_name = res?.record?.response[0]?.film_name;
        getList({
          listUrl: "/contact/getcontacts",
          field: "film_name",
          search: film_name,
          sort: "asc",
          page: 1,
          count: 100,
        }).then((reslist) => {
          console.log("FilmNameChange .list ", reslist);
          if (reslist.success) {
            reslist = reslist?.data.list;
            if (reslist.length === 1) {
              store.getvalue("contact_id") === "" &&
                store.setvalue("contact_id", reslist[0].id || "");
              store.getvalue("contact_id") === "" &&
                ClientNameChange(reslist[0].id || "");
            } else {
              store.setvalue("contact_id", "");
            }

            let list = [];
            for (let record of reslist) {
              list.push({ value: record.id, label: record.clientName });
              console.log("FilmNameChange .record - ", record);
            }
            store.setprops("clientName_list", list);
          }
        });
        // Artist list for the project_id
        getList({
          listUrl: "/forms/getformslist",
          field: "film",
          search: film_name,
          sort: "asc",
          page: 1,
          count: 100,
        }).then((artist) => {
          console.log("projectNameChange artist", artist);

          if (artist.success) {
            artist = artist?.data?.list || [];
            if (artist.length === 1) {
              store.getvalue("form_id") === "" &&
                store.setvalue("form_id", artist[0].id || "");
              store.getvalue("form_id") === "" && FormNameChange(artist[0].id);
            }

            let list = [];
            for (let artisteach of artist) {
              list.push({
                value: artisteach.id,
                label: artisteach.name_of_Artist,
              });
            }
            store.setprops("name_of_Artist_list", list);
          }
        });
      }
    });
  }
  function ClientNameChange(client_id) {
    console.log("ClientNameChange");

    let films = [];
    GetRecords("/contact/contacts/" + client_id).then((contacts) => {
      if (contacts.success) {
        contacts = contacts?.record?.parent[0] || {};
        store.getvalue("film_id") === "" &&
          store.setvalue("film_id", contacts.film_Id || "");
        getList({
          listUrl: "/project/getprojectlist",
          field: "contact_id",
          search: contacts.id,
          sort: "asc",
          page: 1,
          count: 100,
        }).then((project) => {
          if (project.success) {
            project = project.data.list;
            if (project.length === 1) {
              store.getvalue("project_id") === "" &&
                store.setvalue("project_id", project[0].id || "");
              store.getvalue("project_id") === "" &&
                projectNameChange(project[0].id || "");
            } else {
              store.setvalue("project_id", "");
            }

            let list = [];
            for (let record of project) {
              list.push({ value: record.id, label: record.projectName });
            }
            store.setprops("projectName_list", list);
          }
        });
      }
    });
  }
  function projectNameChange(project_id) {
    GetRecords("/project/getproject/" + project_id).then((res) => {
      res = res?.record && res?.record.parent;
      console.log("projectNameChange", res);
      store.getvalue("contact_id") === "" &&
        store.setvalue("contact_id", res?.contact_id || "");
      store.getvalue("film_id") === "" && FilmNameChange(res?.film_id);
      store.getvalue("film_id") === "" &&
        store.setvalue("film_id", res?.film_id || "");

      // By choosing the project getting the Document list
      getList({
        listUrl: "/tasks/getprojectstasklist",
        field: "project_id",
        search: project_id,
        sort: "asc",
        page: 1,
        count: 100,
      }).then((task) => {
        if (task.success) {
          task = task?.data?.list || [];
          if (task.length === 1) {
            store.getvalue("task_id") === "" &&
              store.setvalue("task_id", task[0].id || "");
            store.getvalue("task_id") === "" && TaskNameChange(task[0].id);
          }

          let list = [];
          for (let taskeach of task) {
            list.push({
              value: taskeach.id,
              label: taskeach.task_title,
            });
          }
          store.setprops("task_title_list", list);
          console.log("FormNameChange task_title_list", list);
        }
      });
      // GetRecords("/api/FilmMaster/films/" + res?.film_id).then((film) => {
      //   if (film.success) {
      //     film = film?.record?.response[0]?.film_name;
      //   }
      //   console.log("projectNameChange flim", film);
    });
  }
  function FormNameChange(form_id) {
    GetRecords("/forms/getforms/" + form_id).then((res) => {
      console.log("FormNameChange", form_id, res);
      res = res?.record && res?.record[0].form;
      store.getvalue("contact_id") === "" && ClientNameChange(res?.contact_id);
      store.getvalue("contact_id") === "" &&
        store.setvalue("contact_id", res?.contact_id);
      store.getvalue("film_id") === "" &&
        store.setvalue("film_id", res?.film_id);

      getList({
        listUrl: "/tasks/getprojectstasklist",
        field: "form_id",
        search: form_id,
        sort: "asc",
        page: 1,
        count: 100,
      }).then((task) => {
        if (task.success) {
          task = task?.data?.list || [];
          if (task.length === 1) {
            store.setvalue("task_id", task[0].id || "");
          }

          let list = [];
          for (let taskeach of task) {
            list.push({
              value: taskeach.id,
              label: taskeach.task_title,
            });
          }
          store.setprops("task_title_list", list);
          console.log("FormNameChange task_title_list", list);
        }
      });
    });
  }
  function TaskNameChange(task_id) {
    GetRecords("/tasks/gettask/" + task_id).then((res) => {
      if (res.success) {
        res = res?.record?.parent[0] || {};
        console.log("ChangeprojectName", res, res?.contact_id);

        store.getvalue("contact_id") === "" &&
          store.setvalue("contact_id", res?.contact_id || "");
        store.getvalue("form_id") === "" &&
          store.setvalue("form_id", res?.form_id || "");
        store.getvalue("project_id") === "" &&
          projectNameChange(res?.project_id);
        store.getvalue("project_id") === "" &&
          store.setvalue("project_id", res?.project_id || "");
        store.getvalue("taskid") === "" &&
          store.setvalue("taskid", res?.taskid || "");
        store.getvalue("assigned_to") === "" &&
          store.setvalue("assigned_to", res?.assigned_to || "");
        store.getvalue("film_id") === "" &&
          store.setvalue("film_id", res?.film_id || "");
      }
    });
  }
  return (
    <FormView
      actions={[
        {
          Name: "Back",
          onclick: () => {
            // user.role === 1
            //   ? store.navTo("/" + URLPRIFIX + "/tasks/list")
            //   : store.navTo(
            //       "/" +
            //         URLPRIFIX +
            //         "/tasks/list?field=assigned_to_username&search=" +
            //         user.name
            //     );
            store.navback();
            store.reset(init);
          },
          visible: true,
        },
        {
          Name: "Submit",
          onclick: Submit,
          type: "primary",
          visible: id === undefined,
        },
        {
          Name: "Update",
          onclick: update,
          type: "primary",
          visible: option === "edit" && store.getprops("update"),
        },
        {
          Name: "Start",
          onclick: inprogress,
          type: "primary",
          visible:
            option === "view" &&
            (store.getvalue("status") === "Draft" ||
              store.getvalue("status") === "hold" ||
              store.getvalue("status") === "rejected"),
        },
        {
          Name: "hold",
          onclick: () => {
            store.setprops("hold_note", {
              hidden: false,
              readonly: false,
              mandatory: true,
            });
            store.setprops("Holdpopup", { hidden: false });
          },
          visible:
            option === "view" && store.getvalue("status") === "inprogress",
        },

        {
          Name: "Reject",
          onclick: () => {
            store.setprops("rejected_reason", {
              hidden: false,
              readonly: false,
              mandatory: true,
            });
            store.setprops("rejectpopup", { hidden: false });
          },
          visible:
            option === "view" &&
            store.getvalue("status") === "waitingforapproval" &&
            user?.id == "1",
        },
        {
          Name: "Approve",
          onclick: () => {
            approved();
          },
          type: "primary",
          visible:
            option === "view" &&
            store.getvalue("status") === "waitingforapproval" &&
            user?.id == "1",
        },
        {
          Name: "completed",
          onclick: () => {
            store.setprops("complete_note", {
              hidden: false,
              readonly: false,
              mandatory: true,
            });
            store.setprops("completePopup", { hidden: false });
          },
          type: "primary",
          visible:
            option === "view" && store.getvalue("status") === "inprogress",
        },
        {
          Name: "Open Editor",
          onclick: () => {
            store.navtotab(
              "/" +
                URLPRIFIX +
                "/editor/" +
                (store.getvalue("editor_id") || "1")
            );
          },
          type: "primary",
          visible:
            option === "view" &&
            store.getvalue("status") !== "Draft" &&
            store.getvalue("status") !== "waitingforapproval" &&
            store.getvalue("status") !== "completed" &&
            store.getvalue("status") !== "rejected",
        },
      ]}
    >
      {store.getprops("rejectpopup") &&
        store.getprops("rejectpopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Journal
                name="rejected_reason"
                label="Reject Reason :"
                doubleline={true}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={rejected}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("rejected_reason", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("rejectpopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      {store.getprops("Holdpopup") &&
        store.getprops("Holdpopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Journal
                name="hold_note"
                label="Hold Reason :"
                doubleline={true}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={hold}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("hold_note", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("Holdpopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      {store.getprops("completePopup") &&
        store.getprops("completePopup").hidden === false && (
          <div className="absolute top-0 left-0 flex h-full w-full justify-center items-center bg-black bg-opacity-35 z-30 rounded-2xl">
            <div className="bg-secondary p-9 w-[50%] rounded-2xl">
              <Journal
                name="complete_note"
                label="Completion Notes :"
                doubleline={true}
              />
              <div className="flex justify-end items-center px-[2%] pt-[2%] gap-5">
                <button
                  className="bg-primary text-secondary py-3 px-10 rounded-xl font-bold"
                  onClick={complete}
                >
                  Ok
                </button>
                <button
                  className="bg-tertiary text-primary py-3 px-10 rounded-xl font-bold"
                  onClick={() => {
                    store.setprops("complete_note", {
                      hidden: true,
                      readonly: false,
                    });
                    store.setprops("completePopup", { hidden: true });
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          </div>
        )}
      <div className="grid grid-cols-2 max-md:grid-cols-1 gap-3">
        <String name="subtask_title" label="Task Title :" />
        <Dropdown
          name="film_id"
          label="Film Name :"
          list={store.getprops("film_name_list") || []}
          onchange={FilmNameChange}
        />
        <Dropdown
          name="contact_id"
          label="Client Name :"
          list={store.getprops("clientName_list")}
          onchange={ClientNameChange}
        />
        <Dropdown
          name="project_id"
          label="Project Name :"
          list={store.getprops("projectName_list") || []}
          onchange={projectNameChange}
        />
        <Dropdown
          name="form_id"
          label="Artist Name :"
          list={store.getprops("name_of_Artist_list") || []}
          onchange={FormNameChange}
          onView={(id) => {
            store.navTo("/" + URLPRIFIX + "/forms/view/" + id);
          }}
        />
        <Dropdown
          name="task_id"
          label="Document Name :"
          list={store.getprops("task_title_list") || []}
          onchange={TaskNameChange}
        />
        <Dropdown
          name="assigned_to"
          label="Assigned To :"
          list={store.getprops("userName_list") || []}
        />
        <Dropdown
          name="status"
          label="Status :"
          list={[
            { value: "Draft", label: "Draft" },
            { value: "inprogress", label: "inprogress" },
            { value: "hold", label: "Hold" },
            { value: "waitingforapproval", label: "Waiting For Approval" },
            { value: "approved", label: "Approved" },
            { value: "rejected", label: "Rejected" },
            { value: "completed", label: "Completed" },
            { value: "closedcompleted", label: "Closed Completed" },
          ]}
        />
        <DatePicker name="dueDate" label="Due Date :" />
        <div className="col-span-2 max-md:col-span-1">
          <Journal
            name="description"
            label="Document Description :"
            doubleline={true}
          />
        </div>

        <Dropdown
          name="priority"
          label="Priority :"
          list={[
            { value: "High", label: "High" },
            { value: "Medium", label: "Medium" },
            { value: "Low", label: "Low" },
          ]}
        />
        <Dropdown
          name="activeStatus"
          label="Active Status :"
          list={[
            { value: true, label: "Active" },
            { value: false, label: "InActive" },
          ]}
        />
        <div className="col-span-2  max-md:col-span-1 px-2 ">
          <div className="font-bold text-[20px]">Attachements</div>
          <div className="grid grid-cols-5">
            {store.getvalue("attachmenturl")?.length > 0 ? (
              store.getvalue("attachmenturl").map((item, index) => {
                // console.log("test 223", item);
                return (
                  <div className="col-span-1 flex border-r pr-6 w-full  items-center">
                    <Attachement name={"attachmenturl"} fileurl={item} />
                    <button
                      className="hover:bg-tertiary p-1 h-fit w-fit rounded-full"
                      onClick={() => {
                        let arr = [];
                        store
                          .getvalue("attachmenturl")
                          .map((attachitem, attachindex) => {
                            if (attachindex !== index) {
                              arr.push(attachitem);
                            }
                          });
                        store.setvalue("attachmenturl", arr);
                      }}
                    >
                      X
                    </button>
                  </div>
                );
              })
            ) : (
              <>No Data</>
            )}
            {option !== "view" && (
              <FileInput
                prefix={"TASK" + id}
                onchange={(file) => {
                  let attachmenturl = store.getvalue("attachmenturl") || [];
                  attachmenturl.push(file);
                  store.setvalue("attachmenturl", attachmenturl);
                }}
              />
            )}
          </div>
        </div>
        <Journal name="hold_note" label="Hold Reason :" doubleline={true} />
        <Journal
          name="complete_note"
          label="Completion Notes :"
          doubleline={true}
        />
        <Journal
          name="rejected_reason"
          label="Rejection Notes :"
          doubleline={true}
        />
      </div>
    </FormView>
  );
};

export default TasksForm;
